import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { Observable } from "rxjs/Rx";
import * as config from './providers/config';

@Injectable()
export class ScreenBuilderApiService {

    private headers = new Headers({
        'Content-Type': 'application/json',
        'charset': 'UTF-8'
    });
    private options = new RequestOptions({ headers: this.headers });
    // token = sessionStorage.getItem('token');
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private http: Http, private _httpClient: HttpClient) { }

    getData(payLoad) {
        // payLoad["db"] = this.dbName;
        payLoad["token"] = sessionStorage.getItem('token');;
        return payLoad;
    }

    retrieveMongoDBAll(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVESCREENBUILDERALL, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    
    retrieveMongoDBModel(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEMODELS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveScreenDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEFROMLOOKUP, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveScreenDetailsGrouped(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEFROMLOOKUPGROUPED, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    //Module-wise end points
    //Customer module
    retrieveKYCScreenbuilder(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEKYCSCREENBUILDER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    //Dealer module

    retrieveDealerAddressScreenbuilder(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEDEALERADDRESSSCREENBUILDER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    

}
