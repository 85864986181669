import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule } from '@angular/material';

import { FuseSearchBarModule, FuseShortcutsModule } from '@crecientech/components';
import { FuseSharedModule } from '@crecientech/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { AppStateService } from 'app/app-state-service';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports     : [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{

    versionNumber :string;
    constructor(private appStateService : AppStateService){
        this.versionNumber = this.appStateService.autoportalVersionNo;
    }

}
