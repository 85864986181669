import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AutoportalApiService } from 'app/autoportal-api-service';
import { AppStateService } from 'app/app-state-service';

@Component({
  selector: 'app-emi-calculator',
  templateUrl: './emi-calculator.component.html',
  styleUrls: ['./emi-calculator.component.scss']
})
export class EmiCalculatorComponent implements OnInit {
  form: FormGroup;
    loanAmount: any;
    APR: any;
    termMonths: any;
    EMI: any;
    showTable:boolean=false;
    currency;
    EMIValue=[]
    loanAmountArray=[]
  constructor(private formBuilder: FormBuilder,
              private autoportalApiService: AutoportalApiService,
              private appStateService:AppStateService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
        loanAmount: ['',[Validators.min(0)]],
        rateOfInterest  :  ['',[Validators.required,Validators.min(0),Validators.required,Validators.max(100)]],
        tenor  :  ['',[Validators.min(0)]],
        EMI  :  ['',[Validators.min(0)]],
    })
    this.setCurrency()
    // this.appStateService.setCurrency();
    // this.currency = this.appStateService.currencyDetails.currency;
    
  }

//   changeParams(){
//     this.loanAmount =  this.form.controls['loanAmount'].value 
//     this.APR = this.form.controls['rateOfInterest'].value 
//     this.termMonths = this.form.controls['tenor'].value 
//     console.log('this.termMonths:'+JSON.stringify(this.termMonths))
//   }

  calculateEmi(){
    // this.loanAmount =  this.form.controls['loanAmount'].value 
    this.loanAmount = this.form.get('loanAmount').value
    this.APR = this.form.get('rateOfInterest').value 
    this.termMonths = this.form.get('tenor').value 
    this.EMI = this.form.get('EMI').value


    console.log('loanAmount-->'+JSON.stringify(this.loanAmount))
    console.log('APR-->'+JSON.stringify(this.APR))
    console.log('termMonths-->'+JSON.stringify(this.termMonths))
    console.log('emi-->'+JSON.stringify(this.EMI))

    //case 1 -given loan amount, rate of interest and tenor, calculate EMI for that tenor
    // if(this.loanAmount!="" && this.APR!="" && this.termMonths!="" && this.EMI==""){
        if(this.loanAmount && this.APR && this.termMonths){
        console.log('case 1')
        var params1 = {
            "type":"installments",
            "loanAmount":  this.loanAmount,
            "APR": this.APR,
            "termMonths": this.termMonths
        }
  
        this.autoportalApiService.calculateEMI(params1)
        .then(res=>{
        //   console.log('res from EMI: '+JSON.stringify(res))
          var installment = res.response.installments.installments[0].installment
          this.form.controls['EMI'].setValue(installment)
        })
        .catch(err=>{
            console.log('Error->'+(err))
        })
    }

    //case 2 given loan amt,rate of interest, no tenor ->calculate EMI for 20 years
    else if(this.loanAmount && this.APR){
        // console.log('case 2')
        // this.showTable = true;
        var params2 = {
            "type":"installmentsNoTenor",
            "loanAmount":  this.loanAmount,
            "APR": this.APR,
            "termMonths": 12
        }
        this.autoportalApiService.calculateEMI(params2)
        .then(res=>{
            console.log('case 2 response==>'+JSON.stringify(res))
            this.showTable = true;
            this.loanAmountArray = res.response.loanAmount
            // .filter(item=>{
            //     console.log("item---->"+JSON.stringify(this.appStateService.currencyFormat(item.loanAmount.toFixed(2))));
            //     return this.appStateService.currencyFormat(item.loanAmount.toFixed(2))
            // });
        })
        .catch(err=>{
            console.log('Error->'+(err))
        })
    }

    //case 3 given emi,rate of interest ,no tenor calculate Loan Amount for 20 years
    else if(this.EMI  && this.APR && this.termMonths==null && this.loanAmount==null){
        // console.log('in case 3')
        // this.showTable = true;
        var params3 = {
            "type":"Amount",
            "monthlyPayment":this.EMI,
            "APR": this.APR,
            "termMonths": 12
        }
        this.autoportalApiService.calculateEMI(params3)
        .then(res=>{
          console.log('res from EMI case 3: '+JSON.stringify(res))
          this.showTable = true;
            
            this.loanAmountArray = res.response.loanAmount
            // .filter(item=>{
            //     console.log("item---->"+JSON.stringify(this.appStateService.currencyFormat(item.loanAmount.toFixed(2))));
            //     return this.appStateService.currencyFormat(item.loanAmount.toFixed(2))
            // });
            console.log('this.loanAmountArray:'+JSON.stringify(this.loanAmountArray))
            this.EMIValue = res.response.installments
        })
        .catch(err=>{
            console.log('Error->'+(err))
        })
    }
     
  }

  reset(){
      this.showTable=false;
      this.form.reset();
  }

  setCurrency() {
    var params = {
        // "collectionName": 'ashostorganization',
        "_id": this.appStateService.encryptedDataValue('hostId'),
         "endPoint":"FETCHCURRENCY"
    }

    this.autoportalApiService.fetchCurrency(params)
        .then((res) => {    
            // console.log("currency response:--"+JSON.stringify(res))
            this.currency = res.response[0].currency;
            // console.log(this.currency)
        })
        .catch(err => {
            console.log('Error:' + err);
        })
}
}
