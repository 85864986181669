export const SERVERTYPE = "PRODUCTION";
export const SERVER_URL_DEV = "https://app.lamico.co/api"; // for Local Development Url 

//DBs
export const AUTOPORTALDBNAME = 'lamicolep'
export const SCREENBUILDERDBNAME = 'screenbuilder-lamicolep'



// export const AUTOPORTALDBNAME = 'autoportaluat'
// export const SCREENBUILDERDBNAME = 'screenbuilder-autoportaluat'


// export const SIGNIN = "/api/user/signIn";
export const SIGNIN = "/api/user/mongoSignIn";
export const SIGNUP = "./api/user/mongoSignUp";
export const UPDATEUSER = "/api/user/updateUsers";
export const RETRIVEPROFILE = './api/user/retrieveProfileData';
export const UPDATEPROFILE = './api/user/updateprofile';
export const COMPARECURRENTPASSWORD = './api/user/comparePassword';
export const RESETPASSWORD = './api/user/mongoResetPassword';

// export const RETRIEVEALL = "/api/mysqlDb/retrieveAll"
export const RETRIEVEALL = "/models/prospects/customer-details.model"

//Sample DB related end points
export const RETRIEVECUSTOMERDETAILS = "api/prospects-customer-details"
export const RETRIEVENEWINVENTORYDETAILS = "api/new-inventory-details"
export const RETRIEVEUSEDINVENTORYDETAILS = "api/used-inventory-details"
export const RETRIEVECUSTOMERFORMLY = "api/customer-detail-formly";
export const RETRIEVEINVENTORYDEMOCONFIG = "api/inventory-demo";
export const RETRIEVEVALUATIONDETAILS = "api/valuation-details";

//Mongo DB Related end-points

export const RETRIEVEMONGOALL = "./api/mongoDb/retrieveAll";
export const RETRIEVESCREENBUILDERALL = "./api/mongoDb/retrieveAllScreenbuilder";
export const CREATEMONGODATA = './api/mongoDb/createData';
export const UPDATEMONGODATA = './api/mongoDb/updateData';
export const DELETEMONGODATA = './api/mongoDb/deleteData'
export const RETRIEVEMONGOONE = './api/mongoDb/retrieveOne';
export const RETRIEVEVEHICLEORDER = './api/mongoDb/getVehicleOrder'
export const RETRIEVEVEHICLEORDERWITHSTATUS = './api/mongoDb/getVehicleOrderWithStatus'
export const RETRIEVELPODETAILS = './api/mongoDb/getLPODetails'
export const RETRIEVERFFDETAILSFORBANK = './api/mongoDb/getRFFDetailsForBank'
export const RETRIEVEDEALERANDVEHICLEDETAILSFORBANK = './api/mongoDb/getDealersVehicleOrderDataForBanks'
export const RETRIEVECUSTOMERDATA = './api/mongoDb/getCustomerData'
export const MORTGAGAEDATA = './api/mongoDb/getMortgageData'

export const EMICALCULATOR = './api/autoportal/emiCalculator'
export const CHANGEPASSWORD = '/api/user/mongoChangePassword';
export const BANKDETAILS = '/api/user/getBankDetails';
export const BANKDETAILSJOIN = '/api/user/getBankDetailsJoin';
export const VERIFYTOKEN = '/api/user/verifyToken';

export const ATTACHMENTFILE = '/api/attachment/saveMultipleAttachment';
export const DELETEATTACHMENT = '/api/attachment/deleteAttachment';
export const SINGLEATTACHMENT = '/api/attachment/singleAttachment';
export const SINGLEATTACHMENTNODOCS = '/api/attachment/singleAttachmentNoDocs';
export const ATTACHMENTCSV = '/api/attachment/uploadDirectToS3Csv';
export const UPDATEATTACHMENT = './api/attachment/updateAttachment';

export const FILTERDATA = '/api/search/searchFilterData';
//Lookup related end-point for screenbuilder

export const RETRIEVEFROMLOOKUP = '/api/utilities/getScreenBuilder';
export const RETRIEVEFROMLOOKUPGROUPED = '/api/utilities/getScreenBuildergrouped';
export const RETRIEVEMODELS = '/api/utilities/lookupModels';

// export const RETRIEVEMODELS = "./api/mongoDb/lookupModels";
// export const RETRIEVEBANKCOMMISSIONDETAILS = 'api/vehicalorder/getBankCommission';
export const RETRIEVEBANKCOMMISSIONDETAILS = 'api/appVehicalOrder/getBankCommission';
export const RETRIEVEDATAFORVALUATION = './api/mongoDb/getDetailsForValuation'
export const GETUNIQUECODE = '/api/utilities/getCode';
export const SENDNOTIFICATIONEMAIL = '/api/notification/sendNotificationEmail'

//Module-wise mongo db custom end points -  

//Module-- Customer
export const CREATECUSTOMERDATA = './api/customer/createCustomer'
// export const CREATEADDRESSDATA = './api/customer/createAddress'
export const CREATEEMPLOYMENTDATA = './api/customer/createEmployment'
export const UPDATECUSTOMERDATA = './api/customer/updateCustomer'
export const UPDATEEMPLOYMENTDATA = './api/customer/updateEmployment'
export const RETRIEVEEMPLOYMENTDATA = './api/customer/retrieveEmploymentData'
export const RETRIEVEALLCUSTOMERDATAFORGRID = './api/customer/retrieveAllCustomerDataForGrid'
export const CREATEKYCDATA = './api/customer/createKycData'
export const UPDATEKYCDATA = './api/customer/updateKycData'
export const RETRIEVEKYCSCREENBUILDER = './api/customer/retrieveKYCScreenbuilder'
export const DELETEKYCDATA = './api/customer/deleteKycData'
export const RETRIEVEKYCDATA = './api/customer/retrieveKycData'
export const RETRIEVEALLOPPORTUNITYDATAFORGRID = './api/customer/retrieveAllOpportunityDataForGrid'
export const CREATEOPPORTUNITYDATA = './api/customer/createOpportunityData'
export const RETRIEVEOPPORTUNITYDATAONE = './api/customer/retrieveOpportunityDataOne'
export const UPDATEOPPORTUNITYDATA = './api/customer/updateOpportunityData'


//Module - generic-autoportal--common end points 
export const CREATEADDRESSDATA = './api/autoportal/createAddress'
export const UPDATEADDRESSDATA = './api/autoportal/updateAddress'
export const RETRIEVEADDRESSDATA = './api/autoportal/retrieveAddressData'
export const RETRIEVEATTACHMENTDATA = './api/autoportal/retrieveAttachmentData'
export const DELETEATTACHMENTDATA = './api/autoportal/deleteAttachmentData'
export const FETCHLICENCE = './api/autoportal/fetchLicence'
export const RETRIEVEDEALERLICENCEDATA = './api/autoportal/retrieveDealerLicenceData'
export const FETCHCURRENCY = './api/autoportal/fetchCurrency'
export const RETRIEVELOOKUP = './api/autoportal/retrieveLookupData'

//Module -Dealer
export const RETRIEVEALLDEALERDATAFORGRID = './api/dealer/retrieveAllDealerDataForGrid'
export const CREATEPARTYDEALER = './api/dealer/createPartyDealer'
export const CREATEDEALER = './api/dealer/createDealer'
export const UPDATEDEALER = './api/dealer/updateDealer'
export const RETRIEVEDEALERADDRESSSCREENBUILDER = './api/dealer/retrieveDealerAddressScreenbuilder'
export const RETRIEVEBANKDATA = './api/dealer/retrieveBankData'
export const GETBRANCH = './api/dealer/getBranch'
export const GETIFSC = './api/dealer/getIFSC'
export const FETCHBRANCHNAME = './api/dealer/fetchBranchName'
export const FETCHBANKNAME = './api/dealer/fetchBankName'
export const CREATEDEALERBANKASSOCIATIONDATA = './api/dealer/createDealerBankAssociationData'
export const RETRIEVEDEALERBANKASSOCIATIONDATA = './api/dealer/retrieveDealerBankAssociationData'
export const DEALERLIST = './api/dealer/dealersList'
export const DEALERINDLIST = './api/dealer/dealersIndList'

//Module -Inventory
export const RETRIEVEINVENTORYDATA = './api/inventory/retrieveInventoryData'
export const RETRIEVEINVENTORYDATAONE = './api/inventory/fetchInventoryDataOne'
export const RETRIEVEPRICEDATA = './api/inventory/fetchPriceData'
export const CREATEATTACHMENT = './api/inventory/createAttachment'
export const CREATEPRICEDATA = './api/inventory/createPriceData'
export const UPDATEPRICEDATA = './api/inventory/updatePriceData'
export const CREATEINVENTORY = './api/inventory/createInventory'
export const UPDATEINVENTORY = './api/inventory/updateInventory'
export const RETRIEVEPRODUCTMASTERDATADATAONE = './api/inventory/retrieveProductMasterDataOne'
export const RETRIEVELOOKUPDATA = './api/inventory/retrieveLookupData'
export const RETRIEVEPRODUCTMASTERDATADATAALL = './api/inventory/retrieveProductMasterDataAll'

//Module -Credit Specialist
export const RETRIEVEBANKDEALERASSOCIATIONDATA = './api/creditSpecialist/retrieveBankDealerAssociationData'
export const RETRIEVEDEALERNAMES = './api/creditSpecialist/retrieveDealerNames'
export const RETRIEVEVALUATORS = './api/creditSpecialist/retriveValuators'
export const RETRIEVELPOREQUESTDATAONE = './api/creditSpecialist/retrieveLpoRequestDataOne'
export const UPDATELPOREQUEST = './api/creditSpecialist/updateLpoData'
export const RETRIEVELOANREQVALUE = './api/creditSpecialist/fetchLoanRequestedValue'
export const RETRIEVELPOID = './api/creditSpecialist/retrieveLpoId'
export const DEALERBANKASSOCIATIONDATA = './api/creditSpecialist/dealersBankAssociationRetrive'
export const VALUATORS = './api/creditSpecialist/valuatorsData'
export const CALCULATEMTD = './api/creditSpecialist/getMonthToDateDisbursal'
export const CALCULATEBTD = './api/creditSpecialist/getBusinessToDateDisbursal'

export const RETRIVECREDITSCOREALL = './api/creditSpecialist/retrieveCreditScoreDetails'
export const RETRIVECREDITSCOREONE = './api/creditSpecialist/fetchCreditScoreOne'
export const UPDATECREDITSCORE = './api/creditSpecialist/updateCreditCriteriaData'
export const RETRIVEBANKDETAIL = './api/creditSpecialist/retrieveBankDetails'
export const BANKDETAILSONE = './api/creditSpecialist/fetchbankDetailsOne'
export const UPDATEBANK = './api/creditSpecialist/updateBranch'
export const CREATEPARTYBANK = './api/creditSpecialist/createPartyBranch'
export const CREATEBRANCH = './api/creditSpecialist/createBranch'

export const CREATEBANKASSOCIATION = './api/creditSpecialist/createdealerBankAssociation'
export const UPDATEBRANCH = './api/creditSpecialist/updateBranch'
export const DEALERONE = './api/creditSpecialist/fetchDealerOne'
export const BANKASSOCIATIONALL = './api/creditSpecialist/retrieveDealerbankAssociationAll'
export const DEALERALL = './api/creditSpecialist/retrieveDealerAll'



//Module - RFF Request
export const RETRIEVEDEALERBANKDATA = './api/RFFRequest/retrieveDealerBankData'
export const RETRIEVEBANKNAME = './api/RFFRequest/retrieveBankName'
export const RETRIEVECUSTOMERNAME = './api/RFFRequest/retriveCustomerName'
export const RETRIEVEPRICEDETAILS = './api/RFFRequest/retrievePriceDetails'
export const RETRIEVEINVENTORYDETAILS = './api/RFFRequest/retrieveInventoryDetails'
export const RETRIEVEVEHICLEORDERDETAILS = './api/RFFRequest/retrieveVehicleOrderDetails'
export const CREATERFF = './api/RFFRequest/createRFF'
export const UPDATESTATUSINVEHICLEORDER = './api/RFFRequest/updateStatusInVehicleOrder'
export const UPDATERFFREQUEST = './api/RFFRequest/updateRFFRequest'
export const UPDATEINVENTORYSTATUS = './api/RFFRequest/updateInventoryStatus'
export const GETLPODETAILSFORVEHICLEORDER = './api/RFFRequest/getLPOdetailsForVehicleOrder'
export const GETAPPROVEDVEHICLEORDERS = './api/RFFRequest/getApprovedVehicleOrders'


//Module - Valuation
export const CREATEVALUATORRATING = './api/valuation/createValuatorRating'
export const UPDTEVALUATORDATA = './api/valuation/updateValuatorData'
export const RETRIEVEVALUATORDATA = './api/valuation/retrieveValuationData'
export const RETRIEVERATINGDATA = './api/valuation/getRating'
export const UPDATEVALUATORVALUE = './api/valuation/updateValuatorValue'
export const CREATEVALPRICEDATA = './api/valuation/createValuationPriceData'


//Module - Vehicle Order
export const DELETEVEHICLEORDER = './api/vehicleOrder/deleteVehicleOrder'
export const RETRIEVEVEHICLEORDERONE = './api/vehicleOrder/fetchVehicleOrderOne'
export const CREATEVEHICLEORDER = './api/vehicleOrder/createVehicleOrder'
export const UPDATEVEHICLEORDER = './api/vehicleOrder/updateVehicleOrder'


export const GETSTATUSCOUNT = "./api/mongoDb/getStatusCount";
export const GETDEALERS = "./api/mongoDb/getDealerDippendsOnBankId";
export const DEALERS = "./api/mongoDb/dealers";
export const DALERSPARTYASSOCIATION = "./api/mongoDb/getDealerDippendsOnPartyId";


export const CREATECREDITDATA = './api/inventory/createCreditScore'



export const GETBANKLISTFORPREFERREDBANK = './api/customer/getBankListForPreferredBank'


//Report related endpoints

export const GETOPPORTUNITYCOUNT = '/api/report/getOpportunityCount';
export const GETINVENTORYSTATUS = '/api/report/getInventoryCount';
export const GETBRAND = '/api/report/getBrand';

export const GETLPOCOUNTSTATUS = '/api/report/getLpoStatus';
export const UNIQUESALESEXECUTIVE = '/api/report/retrieveAllUniqueExecutives';
export const UNIQUEBANKS = '/api/report/uniqueBanks'
export const LOANDISBURSEDAMOUNT = '/api/report/getLoanDisbursedAmount'
export const GETREQUESTSTATUS = '/api/report/getRequestStatus';


// Employer 
export const EMPLOYERDETAILS = './api/employerDetails/createEmployerDetails';
export const RETRIEVEALLEMPLOYERDETAILS = './api/employerDetails/retrieveEmployerDetails';
export const RETRIEVEAEMPLOYERDETAILBYID = './api/employerDetails/retrieveEmployerDetailById'
export const RETRIEVEALLAUTHDATABYID = './api/employerDetails/retrieveAllAuthDetailById';
export const ADDEMPLOYERDETAILSINBULK = "./api/employerDetails/uploadEmployerDataInBulk"
export const UPDATEEMPLOYERDETAILSBYID = "./api/employerDetails/updateEmployerDetails";

//Employee
export const CREATEEMPLOYEEDATA = './api/employee/createEmployeeData';
export const UPDATEEMPLOYEEDATA = './api/employee/updateEmployeeData';
export const GETATTCHMENTDETAILBYURL = './api/employee/getAttchmentDetailsByUrl';
export const CREATEVERREQUESTBYBULK = "./api/employee/createloanRequestByBulk";


//Email queue
export const SENDMESSAGE = "./api/sqs/sendMessage";
export const RECEIVEMESSAGE = "./api/sqs/receiveMessage";
export const DELETEMESSAGE = "./api/sqs/deleteMessage";

//Send mail for auth
export const SENDMAILFORAUTH = "./api/sendMailAuth/sendMail";

//Verification request of employee
export const VERIFICATIONREQOFEMPLOYEE = "./api/verificationReq/verificationReqOfEmp";
export const GETVERIFICATIONREQOFEMPLYFINALSTATUS = "./api/verificationReq/getVerificationReqByFinalStatus";
export const GETVERIFICATIONREQOFEMPLYEmployerCode = "./api/verificationReq/getVerificationReqByEmployerCode";
export const GETVERIFICATIONREQOFEMPLYANY = "./api/verificationReq/getVerificationReqByAny";
export const GETVERIFICATIONREQBYCODEANDSTATUS = "./api/verificationReq/getVerificationReqByCodeAndSts";

//Send email for authorization ppl and bank ppl
export const SENDMAILFORAUTHANDBANK = "./api/sendMailAuthAndBank/sendMailToauthAndBank";