import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { Observable } from "rxjs/Rx";
import * as config from './providers/config';
import { plainToClass } from 'class-transformer';

@Injectable()
export class AutoportalApiService {
    token = sessionStorage.getItem('token');

    // private dbName = 'autoportal';
    // private dbName = config.AUTOPORTALDBNAME
    private headers = new Headers({
        'Content-Type': 'application/json',
        'charset': 'UTF-8'
    });
    private options = new RequestOptions({ headers: this.headers });

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private http: Http, private _httpClient: HttpClient) { }

    /* ----------------------------------------------------------------------------------
   **  Http call to  signIn data 
   ------------------------------------------------------------------------------------*/
    signIn(signIn): Observable<any> {
        var url = this.getUrl(config.SERVERTYPE, config.SIGNIN);
        // console.log("calling signIn method" +url);
        return this.http.post(url,
            JSON.stringify(signIn),
            this.options)
            .map(res => res.json()
            );
    }

    signUp(signIn): Observable<any> {
        var url = this.getUrl(config.SERVERTYPE, config.SIGNUP);
        // console.log("calling signIn method" +url);
        return this.http.post(url,
            JSON.stringify(signIn),
            this.options)
            .map(res => res.json()
            );
    }

    /* ----------------------------------------------------------------------------------
  **  Http call to retrive all
  ------------------------------------------------------------------------------------*/
    retrieveAll(retrive): Observable<any> {
        var url = this.getUrl(config.SERVERTYPE, config.RETRIEVEALL);
        console.log("calling retrieveAll method");
        return this.http.post(url,
            JSON.stringify(retrive),
            this.options)
            .map(res => res.json()
            );
    }

    /*----------------------------------------------------------------------------------
      Http call to retrieve data from sample db
    ------------------------------------------------------------------------------------*/
    retrieveData(endpoint): Promise<any> {
        return new Promise((resolve, reject) => {
            var url = this.getUrl(config.SERVERTYPE, endpoint);
            this._httpClient.get(url)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


    // API is get the Apiendpoint url based on the parameter passed handling both Web and Device
    getUrl(type, keyUrl: any): string {
        // if(type == "PRODUCTION"){
        //     return config.SERVER_URL_DEV+keyUrl;
        // }
        return keyUrl;
    }


    /*----------------------------------------------------------------------------------
       Mongo DB Related methods
      ------------------------------------------------------------------------------------*/

    getData(payLoad) {
        // payLoad["db"] = this.dbName;
        payLoad["token"] = sessionStorage.getItem('token');    //this.token;
        return payLoad;
    }

    getEndPoint(endPoint) {
        return config[endPoint]

    }
    retrieveMongoDBAll(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.getEndPoint(params.endPoint), this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveMongoDBOne(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.getEndPoint(params.endPoint), this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createMongodbData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.getEndPoint(params.endPoint), this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateMongodbData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.getEndPoint(params.endPoint), this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    deleteMongodbData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(this.getEndPoint(params.endPoint), this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updatePersonData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEPROFILE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    attachmentFile(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.ATTACHMENTFILE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    attachmentFileCSV(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.ATTACHMENTCSV, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    attachmentSingleFile(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SINGLEATTACHMENT, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    attachmentSingleFileNoDocs(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SINGLEATTACHMENTNODOCS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    filterData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.FILTERDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    deleatAttachmentFile(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.DELETEATTACHMENT, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    changePassword(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CHANGEPASSWORD, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    getBankDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.BANKDETAILS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    getBankDetailsJoin(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.BANKDETAILSJOIN, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    calculateEMI(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.EMICALCULATOR, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    calculateMTD(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CALCULATEMTD, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    calculateBTD(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CALCULATEBTD, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    sendNotificationEmail(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SENDNOTIFICATIONEMAIL, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retriveAllGridData(collectionName, ObjectName): Observable<any> {
        var params = {
            "collectionName": collectionName
        }
        var res;
        this.retrieveMongoDBAll(params).then(
            (res: any) => {
                var dataIdMap = res.response;
                const mapedData = dataIdMap.map((item, index) => {
                    Object.assign(item, { "id": index })
                    return item;
                })
                var result = plainToClass(ObjectName, mapedData);
                console.log("plaintoclass->" + JSON.stringify(result));
                res = result;

            })
            .catch(err => {
                console.log(err);
            })
        return Observable.of(res);
    }

    retrieveVehicleDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEVEHICLEORDER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveVehicleDetailsWithStatus(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEVEHICLEORDERWITHSTATUS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveLPODetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVELPODETAILS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveApprovedVehicleOrders(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETAPPROVEDVEHICLEORDERS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    retrieveBankCommissionDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEBANKCOMMISSIONDETAILS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    getLpoForVehicleOrderId(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETLPODETAILSFORVEHICLEORDER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retrieveRFFDetailsForBank(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVERFFDETAILSFORBANK, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveDealerAndVehicleOrderDataForBank(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEDEALERANDVEHICLEDETAILSFORBANK, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveCustomerDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVECUSTOMERDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    retrieveDataForVehicalValuation(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEDATAFORVALUATION, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    generateUniqueCode(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETUNIQUECODE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    //------------------------------------------------------------//
    // Mongo CRUD Methods Module-wise
    //------------------------------------------------------------//

    //Module - Autoportal-generic 

    createAddressData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEADDRESSDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateAddressData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEADDRESSDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retrieveAddressData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEADDRESSDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retrieveAttachmentData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEATTACHMENTDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    deleteAttachmentData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.DELETEATTACHMENTDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    fetchLicence(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.FETCHLICENCE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retrieveDealerLicenceData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEDEALERLICENCEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    fetchCurrency(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.FETCHCURRENCY, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    //Module - customer

    createCustomerData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            // console.log("this.getData(params) : " + JSON.stringify(this.getData(params)));
            // console.log('token:'+this.token)
            this._httpClient.post(config.CREATECUSTOMERDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createEmploymentData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEEMPLOYMENTDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateCustomerData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATECUSTOMERDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    updateEmploymentData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEEMPLOYMENTDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveEmploymentData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEEMPLOYMENTDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    retrieveAllCustomerDataForGrid(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEALLCUSTOMERDATAFORGRID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createKycData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEKYCDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateKycData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEKYCDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    deleteKycData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.DELETEKYCDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveKycData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEKYCDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveAllOpportunityDataForGrid(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEALLOPPORTUNITYDATAFORGRID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createOpportunityData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEOPPORTUNITYDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveOpportunityDataOne(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEOPPORTUNITYDATAONE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateOpportunityData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEOPPORTUNITYDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    //Module - Dealer

    retrieveAllDealerDataForGrid(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEALLDEALERDATAFORGRID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createPartyDealer(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEPARTYDEALER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createDealer(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEDEALER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    updateDealer(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEDEALER, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    //Module - Inventory


    retrieveInventoryData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEINVENTORYDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    fetchInventoryDataOne(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEINVENTORYDATAONE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    fetchPriceData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEPRICEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createPriceData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEPRICEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updatePriceData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEPRICEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createInventory(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEINVENTORY, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateInventory(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEINVENTORY, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveProductMasterDataOne(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEPRODUCTMASTERDATADATAONE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveLookupData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVELOOKUPDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    retrieveProductMasterDataAll(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEPRODUCTMASTERDATADATAALL, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveStatusCount(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETSTATUSCOUNT, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    retrieveDealers(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETDEALERS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    mortgageData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.MORTGAGAEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    createCreditScoreData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATECREDITDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    dealerbankAssociation(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.DALERSPARTYASSOCIATION, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    dealersData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.DEALERS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    getbanklistforpreferredbanks(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETBANKLISTFORPREFERREDBANK, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    //Report Generation
    getOpportunityCount(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETOPPORTUNITYCOUNT, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    getinventoryStatus(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETINVENTORYSTATUS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    getBrand(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETBRAND, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    ////////////////////////employer details////////////////////////////////////////////////////

    createEmployerDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.EMPLOYERDETAILS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    reterieveAllEmployerDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEALLEMPLOYERDETAILS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    reterieveEmployerDetailById(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEAEMPLOYERDETAILBYID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    reterieveAllAuthDetailById(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.RETRIEVEALLAUTHDATABYID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    UploadEmployerInBulk(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.ADDEMPLOYERDETAILSINBULK, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    updateEmployerDetails(params): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEEMPLOYERDETAILSBYID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    //////////////////////// Employee ////////////////////////////////////////////////////

    createEmployeeData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEEMPLOYEEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    getAttchmentDetails(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETATTCHMENTDETAILBYURL, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }
    updateEmployeeData(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEEMPLOYEEDATA, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }



    //////////////////////// email que ////////////////////////////////////////////////////


    sendMessage(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SENDMESSAGE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    receiveMessage(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(config.RECEIVEMESSAGE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    //////////////////////// email auth ////////////////////////////////////////////////////

    SendMailAuth(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SENDMAILFORAUTH, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }


    verificationReqOfEmp(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.VERIFICATIONREQOFEMPLOYEE, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    verificationReqByStaus(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETVERIFICATIONREQOFEMPLYFINALSTATUS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })

    }

    verificationReqByEmployerCode(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETVERIFICATIONREQOFEMPLYEmployerCode, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })

    }

    verificationReqByAny(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETVERIFICATIONREQOFEMPLYANY, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })

    }
    verificationReqByCodeAndSts(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.GETVERIFICATIONREQBYCODEANDSTATUS, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })

    }

    createVerRequestByBulkUpload(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.CREATEVERREQUESTBYBULK, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })

    }





    retrieveGeneric(params): Promise<any> {

        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEEMPLOYERDETAILSBYID, this.getData(params))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    attachmentUpdateSingleFile(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.UPDATEATTACHMENT, params)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    verifyToken(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.VERIFYTOKEN, params)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }

    // Send email for auth ppl and bank ppl
    sendEmailForAuthAndBank(params): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(config.SENDMAILFORAUTHANDBANK, params)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        })
    }




}