export class ValuationDetails{
    public static valuationData = [
        {
            "id":1,
            "carId":"Tesla-1001",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"4.6",
            "status":"Available",
            "height" : "4",
            "width":"3.8",
            "capacity":"5",
            "fuelType":"Diesel",
            "ltv":"175000",
            "imageUrl":"assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png'],
            "torque":"20",
            "rc":{
                    "availability":"Available",
                    "ownerName":"Demo",
                    "vehicleNumber":"APN12353",
                    "engineNumber":"PLJ14ASK",
                    "chassisNumber":"Pxdg123",
                    "dateOfIssue":"20/02/19",
                    "purchaseDate":"05/02/19",
                    "validity":"20/02/45",
                    "taxUpto":"30/12/25"
                },
            "insurance":{
                    "availability":"Available",
                    "policyRefNumber":"Dfn1234",
                    "area":"demo",
                    "insured":"PLJ1233SS",
                    "period":"3",
                    "party":"First",
                    "totalValue":"240000",
                    "contactNumber":"1234567890",
                    "insurancePrice":"200000"
                },    
            "cce":{
                    "availability":"Available",
                    "demo1":"demo",
                    "demo2":"demo",
                    "demo3":"demo",
                    "demo4":"demo",
                    "demo5":"demo",
                    "demo6":"demo",
                    "demo7":"demo",
                    "demo8":"demo",    
            },
            "bluebook":"180000",
            "marketValue":"170000",
            "valuators":"175000"    
        },   
        {
            "id":2,
            "carId":"Tesla-1002",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"3.4",
            "status":"Booked",
            "height" : "4",
            "width":"3.8",
            "capacity":"5",
            "fuelType":"Diesel",
            "ltv":"185000",
            "imageUrl":"assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png'],
            "torque":"20",
            "rc":{
                    "availability":"Available",
                    "ownerName":"Demo",
                    "vehicleNumber":"APN12353",
                    "engineNumber":"PLJ14ASK",
                    "chassisNumber":"Pxdg123",
                    "dateOfIssue":"20/02/19",
                    "purchaseDate":"05/02/19",
                    "validity":"20/02/45",
                    "taxUpto":"30/12/25"
                },
            "insurance":{
                    "availability":"Available",
                    "policyRefNumber":"Dfn1234",
                    "area":"PLJ1233SS",
                    "period":"3",
                    "party":"First",
                    "totalValue":"240000",
                    "contactNumber":"1234567890",
                    "insurancePrice":"200000"
                },    
            "cce":{
                "availability":"Available",
                    "demo1":"demo",
                    "demo2":"demo",
                    "demo3":"demo",
                    "demo4":"demo",
                    "demo5":"demo",
                    "demo6":"demo",
                    "demo7":"demo",
                    "demo8":"demo",    
            }   ,
            "bluebook":"190000",
            "marketValue":"180000",
            "valuators":"185000"    
        },
        {
            "id":3,
            "carId":"Tesla-1003",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"2.5",
            "status":"Not Available",
            "height" : "4",
            "width":"3.8",
            "capacity":"5",
            "fuelType":"Diesel",
            "ltv":"195000",
            "imageUrl":"assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png'],
            "torque":"20",
            "rc":{
                    "availability":"Available",
                    "ownerName":"Demo",
                    "vehicleNumber":"APN12353",
                    "engineNumber":"PLJ14ASK",
                    "chassisNumber":"Pxdg123",
                    "dateOfIssue":"20/02/19",
                    "purchaseDate":"05/02/19",
                    "validity":"20/02/45",
                    "taxUpto":"30/12/25"
                },
            "insurance":{
                    "availability":"Available",
                    "policyRefNumber":"Dfn1234",
                    "area":"PLJ1233SS",
                    "period":"3",
                    "party":"First",
                    "totalValue":"240000",
                    "contactNumber":"1234567890",
                    "insurancePrice":"200000"
                },    
            "cce":{
                "availability":"Available",
                    "demo1":"demo",
                    "demo2":"demo",
                    "demo3":"demo",
                    "demo4":"demo",
                    "demo5":"demo",
                    "demo6":"demo",
                    "demo7":"demo",
                    "demo8":"demo",    
            }   ,
            "bluebook":"180000",
            "marketValue":"170000",
            "valuators":"175000"    
        },
        {
            "id":4,
            "carId":"Tesla-1004",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"4.6",
            "status":"Available",
        },   
        {
            "id":5,
            "carId":"Tesla-1005",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"3.4",
            "status":"Booked",
        },
        {
            "id":6,
            "carId":"Tesla-1006",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"2.5",
            "status":"Not Available",
        },
        {
            "id":7,
            "carId":"Tesla-1007",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"4.6",
            "status":"Available",
        },   
        {
            "id":8,
            "carId":"Tesla-1008",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"3.4",
            "status":"Booked",
        },
        {
            "id":9,
            "carId":"Tesla-1009",
            "model":"Sp100d",
            "type":"Manual",
            "year":"2016",
            "driven":"50000",
            "condition":"2.5",
            "status":"Not Available",
        },

    ]
}