import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmiCalculatorComponent } from './emi-calculator.component';
import { FuseSharedModule } from '@crecientech/shared.module';
import { MatInputModule, MatFormFieldModule, MatButtonModule, MatTableModule } from '@angular/material';


@NgModule({
    declarations: [
        EmiCalculatorComponent
    ],
    imports:[
        CommonModule,      
        FuseSharedModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatTableModule
    ],
    exports     : [
        EmiCalculatorComponent
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class EmiCalculatorModule
{
}
