export class NewInventoryDetails{
    public static NewInventoryData = [
        {
            "id": 1,
            "brandName": "BMW",
            "model": 'X1',
            "type": "Automatic",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2018,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"/assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png']
        },
        {
            "id": 2,
            "brandName": "Hyundai",
            "model": 'EFGH',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 200,
            "mileage": 5,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":185000,
            "rating":3.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png']
        },
        {
            "id": 3,
            "brandName": "BMW",
            "model": 'WXYZ',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":195000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg",
            "carImages" : ['assets/images/car-images/car-1.png','assets/images/car-images/car-2.png']
        },
        {
            "id": 4,
            "brandName": "Toyota",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 5,
            "brandName": "Tesla",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 6,
            "brandName": "Hyundai",
            "model": 'EFGH',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 200,
            "mileage": 5,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id":7,
            "brandName": "BMW",
            "model": 'WXYZ',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id":8,
            "brandName": "Toyota",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },{
            "id": 9 ,
            "brandName": "Tesla",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id":10,
            "brandName": "Hyundai",
            "model": 'EFGH',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 200,
            "mileage": 5,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 11,
            "brandName": "BMW",
            "model": 'WXYZ',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 12,
            "brandName": "Toyota",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 13,
            "brandName": "Tesla",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 14,
            "brandName": "Hyundai",
            "model": 'EFGH',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 200,
            "mileage": 5,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 15,
            "brandName": "BMW",
            "model": 'WXYZ',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 16,
            "brandName": "Toyota",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },{
            "id": 17,
            "brandName": "Tesla",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 18,
            "brandName": "Hyundai",
            "model": 'EFGH',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 200,
            "mileage": 5,
            "status": "Not Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 19,
            "brandName": "BMW",
            "model": 'WXYZ',
            "type": "Manual",
            "fuelType": "Diesel",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
        {
            "id": 20,
            "brandName": "Toyota",
            "model": 'ABCD',
            "type": "Manual",
            "fuelType": "Petrol",
            "topSpeed": 90,
            "mileage": 12,
            "status": "Available",
            "year" : 2017,
            "height" : 4,
            "width" : 3.8,
            "capacity":5,
            "torque":20,
            "transmission":"Automatic",
            "parkingSensor":"Yes",
            "doors":4,
            "wheels":"Alloy",
            "color":"Red",
            "CLS":"Yes",
            "gears":5,
            "drive":"Front",
            "sportsMode":"No",
            "ltv":175000,
            "rating":4.5,
            "price":200000,
            "imageUrl":"assets/images/cards/car.jpg"
        },
    ]
}