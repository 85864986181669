import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@crecientech/animations';
import { AutoportalApiService } from 'app/autoportal-api-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FuseConfigService } from '@crecientech/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class UpdatepasswordComponent implements OnInit {

  // -------------------------------------------------
  // Global variables
  // -------------------------------------------------

  forgotPasswordForm: FormGroup; // from group
  routerData; // activated router data
  dataForUpdate; // converted activated router data to atob
  responseData; // retrived response data for person email
  emailId: any;
  tempPasswordValue;
  tempPasswordCheck: boolean = false;

  // -------------------------------------------------
  // Constructor
  // -------------------------------------------------

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FormBuilder} _formBuilder
   */
  constructor(private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private router: Router, public activatedRoute: ActivatedRoute,
    private autoportalApiService: AutoportalApiService) { 
      this.routerData = activatedRoute.snapshot.params.data;
      if (this.routerData == undefined || this.routerData == null) {
      }
      else {
        //  var data = window.atob(this.routerData);
        //  console.log("this.routerData : " + JSON.stringify(this.routerData) + " Parsed Data" + window.atob(this.routerData));
        // this.dataForUpdate = window.atob(this.routerData);
        console.log("dataupdate : " + this.routerData)
        this.retrieveData(this.routerData);
  
      }
      // Configure the layout
      this._fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: true
          },
          toolbar: {
            hidden: true
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: true
          }
        }
      };
  
      this.forgotPasswordForm = this._formBuilder.group({
        email: [{ value: this.emailId, disabled: true }],
        password: ['', Validators.compose([Validators.required, Validators.pattern("^(?=.*?[A-Za-z])(?=.*?[0-9]).{2,}$")])],
        tempPassword: ['', Validators.required]
      });}

 
  // -------------------------------------------------
  // ngOnInit
  // -------------------------------------------------

  ngOnInit() { }

  // -------------------------------------------------
  // data retrieved from user table
  // -------------------------------------------------

  retrieveData(personId) {
    var params = {
      "collectionName": "asuser",
      "queryStr": { "personId": { "$eq": personId } },
      "endPoint":"RETRIEVEMONGOALL",
      "module":"dealer"
    }
    this.autoportalApiService.retrieveMongoDBAll(params)
      .then(
        (res: any) => {
          if (res.status == 200) {
            console.log("data response : " + JSON.stringify(res))
            this.responseData = res.response;
            this.emailId = res.response[0].userName;
            this.tempPasswordValue = res.response[0].tempPassword
            this.forgotPasswordForm.controls['email'].setValue(res.response[0].userName)
          }
        })
      .catch(err => {
        console.log(err);
      })
  }

  removeError(formControlName) {
    this.forgotPasswordForm.controls[formControlName].markAsPristine();

    if (formControlName == "tempPassword") {
      this.tempPasswordCheck = false;
    }
  }

  // -------------------------------------------------
  // change password method and navigation to login page if sucess
  // -------------------------------------------------  

  loginToContinue() {
    console.log("update password  ")
    if (this.forgotPasswordForm.controls['password'].valid) {
      var changeTempPassword = {
        "userName": this.responseData[0].userName,
        "oldPassword": "Forgot Password",
        "newPassword": this.forgotPasswordForm.controls['password'].value,
        "confirmPassword": "",
        "tempPassword": this.forgotPasswordForm.controls['tempPassword'].value
      }
      this.autoportalApiService.changePassword(changeTempPassword).then(
        (res: any) => {
          console.log("update password : "+JSON.stringify(res))
          if (res.code == 200) {
            this.router.navigate(['/auth/login']);
          } else if (res.message == "Invalid Temp Password!" && res.code == 400) {
            this.forgotPasswordForm.controls['tempPassword'].setErrors({tempPasswordCheck:true});
          }
        }).catch(err => {
          console.log(err)
        })
    }
  }


}
