import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSelectModule } from '@angular/material';
import { MatListModule } from '@angular/material/list';
import {MatCardModule} from '@angular/material/card'; 
import { TranslateModule } from '@ngx-translate/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import 'hammerjs';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { FuseModule } from '@crecientech/fuse.module';
import { FuseSharedModule } from '@crecientech/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@crecientech/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
// import { SampleModule } from 'app/main/sample/sample.module';
import { LoginModule } from 'app/main/pages/authentication/login/login.module';
import { AutoportalApiService } from './autoportal-api-service';
import { HttpModule } from '@angular/http';
import { CommonModule, CurrencyPipe } from '@angular/common';
// import { NgxPermissionsModule, NgxPermissionsGuard } from 'ngx-permissions';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { SampleDbService } from './sample-db/sample-db.service';
import { ScreenBuilderApiService } from './screenbuilder-api-service';
import { ForgotPasswordModule } from 'app/main/pages/authentication/forgot-password/forgot-password.module';
import { UpdatepasswordModule } from 'app/main/pages/authentication/updatepassword/updatepassword.module';
// import { StarRatingModule } from 'angular-star-rating';
import * as roleConfig from './providers/role-based-permissions';
import { AppStateService } from './app-state-service';
import { ReactiveFormsModule } from '@angular/forms';

// import { AuthguardService } from 'app/authguard.service';
// import { RolesAuthGuardService } from 'app/roles-auth-guard.service';
// import { ParamIdAuthGuardService } from 'app/param-id-auth-guard.service';

const appRoutes: Routes = [
        {
            path        : 'pages',
            loadChildren: './main/pages/pages.module#PagesModule',
        },
        {
            path        : 'apps',
            loadChildren: './main/apps/apps.module#AppsModule',
        }, 
        {
            path      : '**',
            redirectTo: 'auth/login'
        },
      
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
        BrowserModule,
        CommonModule,
        FormlyMaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        ReactiveFormsModule ,
        RouterModule.forRoot(appRoutes,{scrollPositionRestoration:'enabled'}),
        AngularSlickgridModule.forRoot(),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(SampleDbService, {
            delay             : 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatListModule,
        MatCardModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        // SampleModule
         LoginModule,
         ForgotPasswordModule,
         UpdatepasswordModule,
         //NgxPermissions
        //  NgxPermissionsModule.forRoot(),
        //  StarRatingModule.forRoot()
    ],
    providers:[AutoportalApiService,ScreenBuilderApiService,AppStateService,CurrencyPipe,
        // AuthguardService,RolesAuthGuardService,ParamIdAuthGuardService,
        {provide: LocationStrategy, useClass: HashLocationStrategy}],
    bootstrap   : [
        AppComponent
    ],
})
export class AppModule
{
}
