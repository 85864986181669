import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@crecientech/services/config.service';
import { fuseAnimations } from '@crecientech/animations';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AutoportalApiService } from '../../../../autoportal-api-service';
import { FuseNavigationService } from '@crecientech/components/navigation/navigation.service';
import { navigation,sadmin, bankAdmin, Creditspecialist, valuator, lamicoSuperAdminInd } from 'app/navigation/navigation';
import { AppStateService } from 'app/app-state-service';
import * as crypto from 'crypto-js';
import { ScreenBuilderApiService } from 'app/screenbuilder-api-service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    providers: [AutoportalApiService]
})

export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    invalidPassword: boolean = false;
    invalidEmail: boolean = false;
    navigation: any;
    roleId: any;
    roleCode: any;
    partyId: any;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public _router: Router,
        private autoportalApiService: AutoportalApiService,
        private _fuseNavigationService: FuseNavigationService, public appState: AppStateService,
        private screenbuilderApiService: ScreenBuilderApiService
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, Validators.required]
        });

    }

    LoginCheck($ev, value: any) {
        // alert("test");

        for (let c in this.loginForm.controls) {
            this.loginForm.controls[c].markAsTouched();
        }

        if (this.loginForm.valid) {

            var signIn = {
                "collectionName": "asuser",
                "moduleName": "login.component.ts",
                "moduleInfo": "submitForm - signIn service",
                "userName": this.loginForm.value.email,
                "password": this.loginForm.value.password
            }

            this.autoportalApiService.signIn(signIn).subscribe(
                (res: any) => {

                    //console.log('Login response-->' + JSON.stringify(res));
                    if (res.code == 200) {

                        //Set role id
                        this.setApplicationData(res.data);

                        //based on role id set main navigation
                        // this.roleCode = sessionStorage.getItem('roleCode');
                        this._fuseNavigationService.unregister('main');


                        var profile = sessionStorage.getItem('profile');
                        if (profile) {
                            var decrypted = crypto.AES.decrypt(profile, this.appState.encryptSecretKey, this.appState.encryptOptions);
                            if (decrypted.toString()) {
                                var profileJson = JSON.parse(decrypted.toString(crypto.enc.Utf8));
                                this.roleCode = profileJson.roleCode;
                                // this.appState.inventoryType = profileJson.partyId;
                                //console.log("profile : " + JSON.stringify(profileJson))
                            }
                        }

                        // //console.log("login : "+this.roleCode)
                        if (this.roleCode == 103) {
                            this.navigation = sadmin;
                            this._router.navigate(['/apps/bank-details']);
                        }
                        else if (this.roleCode == 107) {
                            this.navigation = bankAdmin;
                            this._router.navigate(['/apps/bank-branch']);
                        }
                        else if (this.roleCode == 108) {
                            this.navigation = Creditspecialist;
                            this._router.navigate(['/apps/dashboards/analytics']);
                        }
                        else if (this.roleCode == 109) {
                            this.navigation = valuator;
                            this._router.navigate(['/apps/valuation-details']);
                        }
                        else if (this.roleCode == 110) {
                            this.navigation = bankAdmin;
                            this._router.navigate(['/apps/bank-branch']);
                        }
                        else if (this.roleCode == 111) {
                            this.navigation = lamicoSuperAdminInd;
                            //console.log("bank view")
                            this._router.navigate(['/apps/create-dealer/bank-view']);
                        }
                        else {
                            this.navigation = navigation;
                            this._router.navigate(['/pages/errors/unauthorized']);
                        }
                        // Register the navigation to the service
                        this._fuseNavigationService.register('main', this.navigation);

                        // Set the main navigation as our current navigation
                        this._fuseNavigationService.setCurrentNavigation('main');

                        //now navigate to respective dashboard
                        // this._router.navigate(['/apps/dashboards/analytics']);

                    }
                    else if (res.code == 400 && res.message == 'Invalid password!') {
                        //   return this.invalidPassword = true;
                        this.loginForm.controls['password'].setErrors({ invalidPassword: true });

                    }
                    else if (res.code == 400 && res.message == 'Invalid Email!') {
                        //    return this.invalidEmail = true;
                        this.loginForm.controls['email'].setErrors({ invalidEmail: true });

                    }
                    else if (res.code == 400 && res.message == 'Inactive!') {
                        Swal.fire({
                            title: 'User Inactive!',
                            text: "Please reset password! ",
                            type: 'warning',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Okay!'
                        })
                    }
                });

        }
    }

    removeErrorMsg(formControlName) {
        this.loginForm.controls[formControlName].markAsPristine();

        if (formControlName == "email") {
            this.invalidEmail = false;
        } else if (formControlName == "password") {
            this.invalidPassword = false;

        }
    }

    //setting local storage
    setApplicationData(loginRes) {
        // if (loginRes.roleCode == 105 || loginRes.roleCode == 106) {
        //     if (loginRes.brand.length > 0) {
        //         var brands = loginRes.brand.map((item) => ({
        //             value: item, label: item
        //         }))
        //         //console.log("brand : " + JSON.stringify(brands))
        //         this.appState.lookupDataBrands = [{ value: '', label: 'All' }, ...brands]
        //         var params = {
        //             "collectionName": "lookUp",
        //             "endPoint": "RETRIEVELOOKUPDATA",
        //             "module": "inventory",
        //             "queryStr": { "lookUpCode": { "$eq": "searchBarSeries" } },
        //         }
        
        //         this.screenbuilderApiService.retrieveMongoDBAll(params)
        //             .then(
        //                 (res: any) => {
        //                     if (res.status == 200) {
        //                         if(res.response.length > 0) {
        //                             //console.log("Models data : " + JSON.stringify(res.response))
        //                             var filtered = res.response[0].data.filter(function(item) {
        //                                 //console.log("items : "+JSON.stringify(item))
        //                                 return loginRes.brand.indexOf(item.did) !== -1;
        //                         });
        //                         var Models = filtered.map((item) => ({
        //                             value: item.empname, label: item.empname
        //                         }))
        //                         this.appState.lookupDataSeries = [{ value: '', label: 'All' }, ...Models]
        //                         }
        //                         //console.log("filtered : "+JSON.stringify(filtered))
        //                     }
        //                 })
        //         //console.log("brand : " + JSON.stringify(brands))
        //         // this.appState.lookupDataBrands = [{ value: '', label: 'All' }, ...brands]
        //         //console.log("brand : " + JSON.stringify(this.appState.lookupDataBrands))
        //     }
        // }
        //console.log("data roleId : " + JSON.stringify(loginRes))
        //console.log("data roleCode : " + JSON.stringify(loginRes.roleCode))
        //console.log("data partyId : " + JSON.stringify(loginRes.partyId))
        //console.log("data personId : " + JSON.stringify(loginRes.personId))
        //console.log("data personEmail : " + JSON.stringify(loginRes.personEmail))
        //console.log("data login : " + JSON.stringify(loginRes))
        sessionStorage.setItem('personId', loginRes.personId);
        sessionStorage.setItem('token', loginRes.token);

        this.appState.globalData.roleId = loginRes.roleId;
        this.appState.globalData.roleCode = loginRes.roleCode;
        this.appState.globalData.partyId = loginRes.partyId;
        this.appState.globalData.personId = loginRes.personId;
        this.appState.globalData.personEmail = loginRes.personEmail;
        this.appState.globalData.orgId = loginRes.orgId;
        this.appState.globalData.orgName = loginRes.orgName;
        this.appState.globalData.orgCode = loginRes.orgCode;
        this.appState.globalData.hostId = loginRes.hostId;
        this.appState.globalData.hostCode = loginRes.hostCode;
        this.appState.globalData.partyCode = loginRes.partyCode;
        this.appState.globalData.token = loginRes.token;
        this.appState.globalData.inventoryType = loginRes.inventoryType;
        this.appState.globalData.logo = loginRes.attahmentUrl;
        this.appState.globalData.inventoryLic = loginRes.invLice;
        var profile = (crypto.AES.encrypt(JSON.stringify(this.appState.globalData),
            this.appState.encryptSecretKey, this.appState.encryptOptions)).toString();
        sessionStorage.setItem('profile', profile);
        this.appState.encryptProfileJson();
        this.appState.setCurrency();
        this.appState.retriveRoleAccessData();
        if(loginRes.url != null) {
            this.appState.setProfileImage(loginRes.url);
            sessionStorage.setItem('profileImage',loginRes.url);
            this.appState.setAvatar(loginRes.url)
          } else {
            this.appState.setAvatar('../../../assets/images/avatars/profile.jpg')
          }
    }

}
