import { InMemoryDbService } from 'angular-in-memory-web-api';


import { AnalyticsDashboardDb } from 'app/sample-db/dashboard-analytics';
import { ProspectsCustomerDetails } from './prospects-customer-details';
import { NewInventoryDetails } from './inventory-new';
import { UsedInventoryDetails } from './inventory-used';
import { CustomerDetailsFormlyFields } from './customer-details-formly-fields';
import { InventoryDetailsFormlyFields } from './inventory-details-demo-fields';
import { ValuationDetails } from './valuation-details';


export class SampleDbService implements InMemoryDbService
{
    createDb(): any
    {
        return {
            // Dashboards
              'analytics-dashboard-widgets': AnalyticsDashboardDb.widgets,
              'prospects-customer-details' : ProspectsCustomerDetails.CustomerData,
              'new-inventory-details' : NewInventoryDetails.NewInventoryData,
              'used-inventory-details' : UsedInventoryDetails.UsedInventoryData,
              'customer-detail-formly' : CustomerDetailsFormlyFields.fields,
              'inventory-demo' : InventoryDetailsFormlyFields.fields,
              'valuation-details':ValuationDetails.valuationData,
        };
    }
}
