import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatepasswordComponent } from './updatepassword.component';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';
import { FuseSharedModule } from '@crecientech/shared.module';
import { RolesAuthGuardService } from 'app/main/apps/roles-auth-guard.service';

const routes = [
  {
    path: 'auth/updatepassword',
    component: UpdatepasswordComponent,
    canActivate: [RolesAuthGuardService],
    data:['auth/updatepassword']
  },
  {
    path: 'auth/updatepassword/:data',
    component: UpdatepasswordComponent,
    canActivate: [RolesAuthGuardService],
    data:['auth/updatepassword/:data']
  }
];

@NgModule({
  declarations: [UpdatepasswordComponent],
  imports: [
    RouterModule.forChild(routes),

    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,

    FuseSharedModule
  ]
})
export class UpdatepasswordModule { }
