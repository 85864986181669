import { FormlyFieldConfig } from '@ngx-formly/core';

export class CustomerDetailsFormlyFields{

   public static fields: FormlyFieldConfig[] =[
        {   
            key:'firstName',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                label: 'First Name',
                placeholder: 'Enter First Name',
                required: true,
                appearance: 'outline',
            }
        },
        {   
            key:'middleName',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                label: 'Middle Name',
                placeholder: 'Enter Middle Name',
                required: true,
                appearance: 'outline',
            }
        },
        {   
            key:'lastName',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                label: 'Last Name',
                placeholder: 'Enter Last Name',
                required: true,
                appearance: 'outline',
            }
        },
        {
            key: 'gender',
            className: 'field-width',
            type: 'radio',
            templateOptions: {
                options: [
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                ],
                required:true,
            },
        },
        {   
            key:'phoneNumber',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type:'number',
                label: 'Phone Number',
                placeholder: 'Enter Phone Number',
                required: true,
                appearance: 'outline',
            }
        },
        {
            key: 'email',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type:'email',
                label: 'Email address',
                placeholder: 'Enter email',
                required: true,
                appearance: 'outline',
            }
        },
        {
            key: 'employmentType',
            className: 'field-width',
            type: 'radio',
            templateOptions: {
                options: [
                    { label: 'Salaried', value: 'salaried' },
                    { label: 'Self Employed', value: 'selfEmployed' },
                ],
                required:true,
            },
        },
        {   
            key: 'company',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Company',
                placeholder: 'Enter Company',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'designation',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Designation',
                placeholder: 'Enter designation',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'doorNumber',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Door Number/Building Name',
                placeholder: 'Enter Door Number/Building Name',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'street',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Street',
                placeholder: 'Enter Street',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'landmark',
        className: 'field-width',
        type: 'input',
        templateOptions: {
            type: 'text',
            label: 'Landmark',
            placeholder: 'Enter Landmark',
            required: false,
            appearance: 'outline'
        }
        },
        {
            key: 'city',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'City',
                placeholder: 'Enter City',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'country',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Country',
                placeholder: 'Enter Country',
                required: false,
                appearance: 'outline'
            }
        },
        {
            key: 'pincode',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Pincode',
                placeholder: 'Enter Pincode',
                required: false,
                appearance: 'outline'
            }
        },
       {
           key: 'prospectScore',
           className: 'field-width',
           type: 'slider',
           templateOptions: {
               label: 'Prospect Score',
               required: true,
               thumbLabel: true,
               tickInterval:'10',
               min: 1,
               max: 100,
               step:10
           }
       }
    ]

}