   export class ProspectsCustomerDetails{
     public static CustomerData = [
        {
            "id": 1,
            "customerName": "Demo-1",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 3,
            "prospectScrore": 70,
            "additionalDetails": "Yes"
        },
        {
            "id": 2,
            "customerName": "Demo-2",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 2,
            "prospectScrore": 90,
            "additionalDetails": "No"
        },
        {
            "id": 3,
            "customerName": "Demo-3",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 4,
            "prospectScrore": 20,
            "additionalDetails": "Yes"
        },
        {
            "id": 4,
            "customerName": "Demo-4",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 3,
            "prospectScrore": 30,
            "additionalDetails": "No"
        },
        {
            "id": 5,
            "customerName": "Demo-5",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 1,
            "prospectScrore": 80,
            "additionalDetails": "Yes"
        },
        {
            "id": 6,
            "customerName": "Demo-6",
            "phone": 987654321,
            "occupation": "Not Salaried","carsInterested": 4,
            "prospectScrore": 40,
            "additionalDetails": "No"
        },
        {
            "id": 7,
            "customerName": "Demo-7",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 2,
            "prospectScrore": 20,
            "additionalDetails": "Yes"
        },
        {
            "id": 8,
            "customerName": "Demo-8",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 3,
            "prospectScrore": 20,
            "additionalDetails": "No"
        },
        {
            "id": 9,
            "customerName": "Demo-9",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 1,
            "prospectScrore": 60,
            "additionalDetails": "Yes"
        },
        {
            "id": 10,
            "customerName": "Demo-10",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 4,
            "prospectScrore": 90,
            "additionalDetails": "No"
        },
        {
            "id": 11,
            "customerName": "Demo-11",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 2,
            "prospectScrore": 50,
            "additionalDetails": "Yes"
        },
        {
            "id": 12,
            "customerName": "Demo-12",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 3,
            "prospectScrore": 70,
            "additionalDetails": "No"
        },
        {
            "id": 13,
            "customerName": "Demo-13",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 1,
            "prospectScrore": 50,
            "additionalDetails": "Yes"
        },
        {
            "id": 14,
            "customerName": "Demo-14",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 4,
            "prospectScrore": 40,
            "additionalDetails": "No"
        },
        {
            "id": 15,
            "customerName": "Demo-15",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 2,
            "prospectScrore": 60,
            "additionalDetails": "Yes"
        },
        {
            "id": 16,
            "customerName": "Demo-16",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 4,
            "prospectScrore": 90,
            "additionalDetails": "No"
        },
        {
            "id": 17,
            "customerName": "Demo-17",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 5,
            "prospectScrore": 40,
            "additionalDetails": "Yes"
        },
        {
            "id": 18,
            "customerName": "Demo-18",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested": 2,
            "prospectScrore": 60,
            "additionalDetails": "No"
        },
        {
            "id": 19,
            "customerName": "Demo-19",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 1,
            "prospectScrore": 30,
            "additionalDetails": "Yes"
        },
        {
            "id": 20,
            "customerName": "Demo-20",
            "phone": 987654321,
            "occupation": "Salaried",
            "carsInterested":4,
            "prospectScrore": 50,
            "additionalDetails": "No"
        },
        {
            "id": 21,
            "customerName": "Demo-21",
            "phone": 987654321,
            "occupation": "Not Salaried",
            "carsInterested": 3,
            "prospectScrore": 20,
            "additionalDetails": "Yes"
        },
    ]

   }

    // export const getModelName = {
    //     "prospects-customer-details" :CustomerData,
    // }