import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { Observable, BehaviorSubject } from "rxjs/Rx";
import * as config from './providers/config';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { AutoportalApiService } from './autoportal-api-service';
import * as crypto from 'crypto-js';
import { JwtHelper } from 'angular2-jwt';
import { EmailValidator ,EmailValidatorMessage ,alphaValidator ,alphaValidatorMessage,
maxValidationMessage ,minValidationMessage ,phoneNumberValidator ,phoneNumberValidatorMessage} from './providers/validationFormly'
import { Router } from '@angular/router';

@Injectable()
export class AppStateService {
    // private dbName = config.SCREENBUILDERDBNAME;
    private headers = new Headers({
        'Content-Type': 'application/json',
        'charset': 'UTF-8'
    });

    currencyDetails = {
        "currency": "",
        "locale": ""
    }

    logo;
    deleteStatus=true;
    
    avatar$: BehaviorSubject<any>;
    lookupDataBrands = [{value:"",label:"All"}];
    lookupDataSeries = [{value:"",label:"All"}];
    lookupDataClass = [{value:"",label:"All"}];
    autoportalVersionNo  :string = "1.0.12";
    globalData: any = {
        roleId: "",
        roleCode: "",
        partyId: "",
        personId: "",
        personEmail: "",
        orgId: "",
        orgCode: "",
        hostId: "",
        hostCode: "",
        partyCode: "",
        token: "",
        inventoryType:"",
        logo:"",
        inventoryLic:""
    }
    inventoryType:string;
    ROLEACCESS;

    encryptSecretKey: any = "KA0f2100e34b54d5VA0fd013df8f300d3497H579O";
    encryptOptions = { mode: crypto.mode.CBC, padding: crypto.pad.Pkcs7 };
    encryptedData: any;
    formlyValidationsRequired: { validators: { name: string; validation: any; }[]; validationMessages: { name: string; message: any; }[]; };

    /**
         * Constructor
         *
         * @param {HttpClient} _httpClient
         */
    constructor(private cp: CurrencyPipe,
                private autoportalApiService: AutoportalApiService,
                private router: Router) {

                    this.avatar$ = new BehaviorSubject(null);
                    // this.currentRouter = new BehaviorSubject(null)
        if (sessionStorage.getItem('profile') != null) {
            this.encryptProfileJson()
            this.setCurrency();
            this.retriveRoleAccessData();
        }
    }

    errorToUnauthorized(code) {
        if(code.status == 500) {
            this.router.navigate(["/pages/errors/unauthorized"]);
        }
        // console.log("unauthorized code : "+JSON.stringify(code))
    }

    encryptProfileJson() {
        // console.log("local storage : " + JSON.stringify(sessionStorage.getItem('profile')))
        var decrypted = crypto.AES.decrypt(sessionStorage.getItem('profile'), this.encryptSecretKey,
            this.encryptOptions);
        if (decrypted.toString()) {
            var profileJson = JSON.parse(decrypted.toString(crypto.enc.Utf8));
            this.encryptedData = profileJson;
        }
    }

    encryptedDataValue(value) {
        // console.log("encrypted data value : " + JSON.stringify(value))
        if (this.encryptedData) {
            // console.log("encrypted data value : " + JSON.stringify(this.encryptedData))
            return this.encryptedData[value];
        }
    }

    setCurrency() {
        var params = {
            // "collectionName": 'ashostorganization',
            "_id": this.encryptedData.hostId,
             "endPoint":"FETCHCURRENCY"
        }

        this.autoportalApiService.fetchCurrency(params)
            .then((res) => {
                console.log("response:--"+JSON.stringify(res))
                this.currencyDetails.currency = res.response[0].currency;
                this.currencyDetails.locale = res.response[0].localeValue;
            })
            .catch(err => {
                console.log('Error:' + err);
            })
    }

    retriveRoleAccessData() {
        var params = {
          "collectionName": "roleAccessJson",
          "endPoint":"RETRIEVEMONGOALL",
          "module":"roleAccess"
        }
        this.autoportalApiService.retrieveMongoDBAll(params)
          .then(
            (res: any) => {
                // console.log("data : "+JSON.stringify(res))
              if(res.status == 200) {
                if(res.response.length != 0) {
                    // console.log("role access : "+JSON.stringify(res.response))
                    this.ROLEACCESS = res.response;
                }
              }
              })
          .catch(err => {
            console.log(err);
          })
      }

    currencyFormat(value) {
        return this.cp.transform(value, this.currencyDetails.currency, 'symbol', '1.2-2', this.currencyDetails.locale);

        //return formatCurrency(value,this.currencyDetails.locale,this.currencyDetails.currency,'','1.0-2')
    }

    // formlyValidations() {
    //     console.log("Formly validations");
    //     this.formlyValidationsRequired = 
    // }

    profileImageAfterSet = '';
    profileImage = "";

    getProfileImage() {
      if (sessionStorage.getItem('profileImage')) {
        this.profileImageAfterSet = sessionStorage.getItem('profileImage');
        return this.profileImage;
      } else {
        this.profileImageAfterSet = '../../../assets/images/avatars/profile.jpg';
        return this.profileImage;
      }
    }
    
  setProfileImage(data) {
    console.log("data profile image : " + JSON.stringify(data))
    this.profileImageAfterSet = data;
  }

  
  getAvatar() {
    return this.avatar$.asObservable();
  }

  setAvatar(value: any) {
    this.avatar$.next(value);
  }
}
