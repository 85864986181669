import { FuseNavigation } from '@crecientech/types';

export const navigation: FuseNavigation[] = [

    {
        id: 'unauthorized',
        title: 'unauthorized',
        translate: 'unauthorized',
        type: 'item',
        icon: 'dashboard',
        url: '/pages/errors/unauthorized',
    }
];

export const bankAdmin: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'Dashboard',
                title: '',
                translate: 'Dashboard',
                type: 'item',
                icon: 'pie_chart',
                url: '/apps/dashboards/analytics',
            },
            {
                id: 'Employer Details',
                title: '',
                translate: 'Employer Details',
                type: 'item',
                icon: 'perm_identity',
                url: '/apps/bank-branch',
            },

            {
                id: 'Verification Request',
                title: '',
                translate: 'Verification Request',
                type: 'item',
                icon: 'description',
                url: '/apps/loan-request-bank',
            },

            // {
            //     id: 'approve',
            //     title: '',
            //     translate: 'approve Screen',
            //     type: 'item',
            //     icon: 'ac_unit',
            //     url: '/apps/approval-screen',
            // },
        ],
    },

    {
        id: 'Setup',
        title: 'Setup',
        translate: 'Setup',
        type: 'collapsable',
        icon: 'settings',
        children: [
            {
                id: 'Credit-specialist',
                title: 'Credit specialist',
                type: 'item',
                url: '/apps/bank-admin-setup/view-creditSpecialist',
                exactMatch: true
            },
            // {
            //     id: 'Credit-specialist',
            //     title: 'Valuators',
            //     type: 'item',
            //     url: '/apps/bank-admin-setup/view-valuators',
            //     exactMatch: true
            // },
            // {
            //     id: 'add-logo-bank',
            //     title: 'Add Logo',
            //     type: 'item',
            //     url: '/apps/bank-admin-setup/add-logo-bank',
            //     exactMatch: true
            // },
        ]
    }
]

export const Creditspecialist: FuseNavigation[] = [
 
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'Dashboard',
                title: '',
                translate: 'Dashboard',
                type: 'item',
                icon: 'pie_chart',
                url: '/apps/dashboards/analytics',
            },
            {
                id: 'Verification Request',
                title: '',
                translate: 'Verification Request',
                type: 'item',
                icon: 'description',
                url: '/apps/loan-request-bank',
            },

           
        ],
    },

]

export const valuator: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'valuation-details',
                title: 'Valuation Details',
                translate: 'Valuation Details',
                type: 'item',
                icon: 'dashboard',
                url: '/apps/valuation-details',
            },
        ]
    }
]

export const sadmin: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
           
            {
                id: 'bank',
                title: 'bank',
                translate: 'Bank',
                type: 'collapsable',
                icon: 'shopping_cart',
                children: [
                    {
                        id: 'bank-details',
                        title: 'Bank Details',
                        type: 'item',
                        url: '/apps/bank-details',
                        exactMatch: true
                    },
                ]
            },
            {
                id: 'setUpEmployess',
                title: 'setUpEmployess',
                translate: 'Setup Employess',
                type: 'collapsable',
                icon: 'shopping_cart',
                children: [
                    {
                        id: 'Sales-Manager',
                        title: 'Sales Manager',
                        type: 'item',
                        url: '/apps/dealer-details/view-employees',
                        exactMatch: true
                    },
                    {
                        id: 'Sales-Executive',
                        title: 'Sales Executive',
                        type: 'item',
                        url: '/apps/dealer-details/view-sales-Executive',
                        exactMatch: true
                    },
                ]
            }
        ]
    },
    
]

export const noNavigation: FuseNavigation[] = [

]

export const lamicoSuperAdminInd: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'Dashboard',
                title: '',
                translate: 'Dashboard',
                type: 'item',
                icon: 'pie_chart',
                url: '/apps/dashboards/analytics',
            },
            {
                id: 'setUp',
                title: 'Setup',
                translate: 'Setup',
                type: 'collapsable',
                icon: 'settings',
                children: [
                    
                    {
                        id: 'Bank',
                        title: 'Create Bank',
                        type: 'item',
                        url: '/apps/create-dealer/bank-view',
                        exactMatch: true
                    }
                ]
            }
        ]
    }
]