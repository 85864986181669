import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule } from '@angular/material';

import { FuseSharedModule } from '@crecientech/shared.module';

import { ForgotPasswordComponent } from 'app/main/pages/authentication/forgot-password/forgot-password.component';
import { RolesAuthGuardService } from 'app/main/apps/roles-auth-guard.service';

const routes = [
    {
        path: 'auth/forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [RolesAuthGuardService],
        data:['auth/forgot-password']
    },
    {
        path: 'auth/forgot-password/:data',
        component: ForgotPasswordComponent,
        canActivate: [RolesAuthGuardService],
        data:['auth/forgot-password/:data']
    }
];

@NgModule({
    declarations: [
        ForgotPasswordComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule
    ]
})
export class ForgotPasswordModule {
}
