import { FormlyFieldConfig } from '@ngx-formly/core';

export class InventoryDetailsFormlyFields{

   public static fields: FormlyFieldConfig[] =[
        {
            key: 'email',
            type: 'input',
            className: 'field-width',
            templateOptions: {
              label: 'Email',
              required: true,
              appearance: 'outline',
            },
            validators: {
              validation: ['email'],
            },
          },
    
        {   
            key:'phoneNumber',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type:'number',
                label: 'Phone Number',
                placeholder: 'Enter Phone Number',
                required: true,
                appearance: 'outline',
            }
        },
       
        {
            key: 'pincode',
            className: 'field-width',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Pincode',
                placeholder: 'Enter Pincode',
                required: true,
                minLength: 3,
                maxLength:6,
                appearance: 'outline'
            }
        },
        {
            key: 'password',
            className: 'field-width',
            type: 'input',
            templateOptions: {
              label: 'Password (minLength = 6)',
              type: 'password',
              required: true,
              minLength: 6,
              appearance: 'outline'
            },
          },  
       {
           key: 'abcd',
           className: 'field-width',
           type: 'input',
           templateOptions: {
               type: 'number',
               label: 'value',
               placeholder: 'Enter a number less than 6',
               required: true,
               appearance: 'outline',
           },
           validators: {
               validation: ['greaterThan'],
           },
       },
       {
           key: 'date',
           className: 'field-width',
           type: 'input',
           templateOptions: {
               type: 'date',
               label: 'date',
               placeholder: 'Enter date',
               required: true,
               appearance: 'outline',
           },
           validators: {
               validation: ['maxDate'],
           },
       },
       {
        key: 'evenNumber',
        className: 'field-width',
        type: 'input',
        templateOptions: {
            type: 'number',
            label: 'Enter an even number',
            placeholder: 'Enter an even number',
            required: false,
            appearance: 'outline',
        },
        validators: {
            validation: ['even'],
        },
    },

    ]

}