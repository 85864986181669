import { Injectable } from '@angular/core';
import { JwtHelper } from 'angular2-jwt';
import { AppStateService } from 'app/app-state-service';
import { CanActivate } from '@angular/router/router';
import { Router, ActivatedRoute } from '@angular/router';
import * as crypto from 'crypto-js';
import { AutoportalApiService } from 'app/autoportal-api-service';
const JSON = require('circular-json');
// const jwt = require('jsonwebtoken');
// import * as jwt from 'jsonwebtoken';

@Injectable({
  providedIn: 'root'
})
export class RolesAuthGuardService implements CanActivate {

  //     restrictedRoutes = ["auth/login", "auth/forgot-password", "auth/forgot-password/:data", "auth/login", "auth/login/:data",
  //         "auth/updatepassword", "auth/updatepassword/:data"];

  //     constructor(private router: Router, private appState: AppStateService) { }

  //     canActivate(route): any {
  //         console.log("sessionStorage.getItem('token') : " + JSON.stringify(sessionStorage.getItem('token')))
  //         let roles = route.data[0] as string;
  //         let token = route.data[1] as string;
  //         console.log("data for route : " + JSON.stringify(this.appState.ROLEACCESS))
  //         var tokenExpir = new JwtHelper();
  //         // tokenExpir.isTokenExpired(sessionStorage.getItem('token'))
  //         // console.log("token expir : " + JSON.stringify(tokenExpir.isTokenExpired(sessionStorage.getItem('token'))))
  //         if (this.appState.ROLEACCESS != undefined) {
  //             var routeData = this.appState.ROLEACCESS.filter((item) => {
  //                 if (roles == item.routeName) {
  //                     return item;
  //                 }
  //             })
  //             console.log("route : " + JSON.stringify(routeData))
  //             var rolesAccess: any[] = routeData[0].roleCode;
  //             var routeName = routeData[0].routeName;

  //             // console.log("current routeName : " + JSON.stringify(routeName))
  //             if (sessionStorage.getItem('token') != null && rolesAccess) {
  //                 var jwtHelper = new JwtHelper().decodeToken(sessionStorage.getItem('token'));
  //                 if (Object.keys(jwtHelper).length && this.restrictedRoutes.includes(routeName) == false) {
  //                     var decrypted = crypto.AES.decrypt(sessionStorage.getItem('profile'), this.appState.encryptSecretKey,
  //                         this.appState.encryptOptions);
  //                     if (decrypted.toString()) {
  //                         var profileJson = JSON.parse(decrypted.toString(crypto.enc.Utf8));
  //                         if (profileJson.roleCode) {
  //                             console.log("profileJson.roleCode : " + JSON.stringify(profileJson.roleCode))
  //                             var curentRoleAccess = rolesAccess.includes(profileJson.roleCode);
  //                             if (curentRoleAccess) {
  //                                 // console.log("current role : "+curentRoleAccess)
  //                                 return true;
  //                             } else {
  //                                 // console.log("current role : "+curentRoleAccess)
  //                                 this.router.navigate(["/pages/errors/unauthorized"]);
  //                                 return false;
  //                             }
  //                         }
  //                     }
  //                 }
  //             } else if (this.restrictedRoutes.includes(routeName)) {
  //                 console.log("includes")
  //                 return true;
  //             } else {
  //                 this.router.navigate(["/auth/login"]);
  //                 return false;
  //             }
  //         } else if (this.restrictedRoutes.includes(routeName)) {
  //             console.log("includes 2")
  //             return true;
  //         } else {
  //             this.router.navigate(["/pages/errors/unauthorized"]);
  //             return false;
  //         }
  //     }
  // }

  restrictedRoutes = ["auth/login", "auth/forgot-password", "auth/forgot-password/:data", "auth/login", "auth/login/:data",
    "auth/updatepassword", "auth/updatepassword/:data"];

  constructor(public appState: AppStateService, public router: Router, public apiService:AutoportalApiService) { }

  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;

  canActivate(route): any {
    var tokenExpir: JwtHelper = new JwtHelper();
    let roles = route.data[0] as string;
    let routeNameUn = route.data[0] as string;
    const json = JSON.stringify(roles);
    console.log("upload data : ", json)
    console.log("local storage data : " + JSON.stringify(sessionStorage.getItem('token')))
    // console.log("local storage data : "+JSON.stringify(route))
    // var routeDataNone = this.appState.ROLEACCESS.filter((item) => roles == item.routeName);
    // var routeNameNone = routeDataNone[0].routeName;
    if (this.appState.ROLEACCESS != null || this.appState.ROLEACCESS != undefined) {
      // var tokenExpir = new JwtHelper();
      // tokenExpir.isTokenExpired(sessionStorage.getItem('token'))
      // console.log("token expir : " + JSON.stringify(tokenExpir.isTokenExpired(sessionStorage.getItem('token'))))
      var routeData = this.appState.ROLEACCESS.filter((item) => roles == item.routeName);
      console.log("route data : " + JSON.stringify(routeData));
      var rolesAccess: any[] = routeData[0].roleCode;
      var routeName = routeData[0].routeName;
      // console.log("token data : " + JSON.stringify(this.appState.encryptedDataValue('token')))
      // console.log("data routename : " + JSON.stringify(this.restrictedRoutes.includes(routeName)))
      if (routeName != 'auth/login') {
        if (sessionStorage.getItem('token') != undefined) {
          var params = {
            "token":this.appState.encryptedDataValue('token')
          }
          return this.apiService.verifyToken(params).then(
            (res) => {
              if(res.code == '200') {
                if (rolesAccess) {
                  var jwtHelper = new JwtHelper().decodeToken(this.appState.encryptedDataValue('token'));
                  console.log("routeName : " + JSON.stringify(routeName))
                  if (Object.keys(jwtHelper).length && this.restrictedRoutes.includes(routeName) == false) {
                    var decrypted = crypto.AES.decrypt(sessionStorage.getItem('profile'), this.appState.encryptSecretKey,
                      this.appState.encryptOptions);
                    if (decrypted.toString()) {
                      var profileJson = JSON.parse(decrypted.toString(crypto.enc.Utf8));
                      if (profileJson.roleCode) {
                        var curentRoleAccess = rolesAccess.includes(profileJson.roleCode);
                        if (curentRoleAccess) {
                          return true;
                        } else {
                          this.router.navigate(["/pages/errors/unauthorized"]);
                          return false;
                        }
                      }
                    }
                  } else if (sessionStorage.getItem('profile') == null) {
                    console.log("restricted route");
                    console.log("local storage data : " + JSON.stringify(sessionStorage.getItem('profile')))
                    // this.router.navigate(["/landing"]);
                    return true;
                  } else {
                    this.router.navigate(["/pages/errors/unauthorized"]);
                    return false;
                  }
                }
              } else {
                sessionStorage.clear();
                this.router.navigate([""]);
                return false;
              }
              console.log("response verify token : "+JSON.stringify(res))
            })
        } else if (this.restrictedRoutes.includes(routeName)) {
          return true;
        } else {
          this.router.navigate([""]);
          return false;
        }
      } else if(sessionStorage.getItem('profile') == null){
        console.log("log out : "+JSON.stringify(sessionStorage.getItem('profile')))
        return true;
      } else {
        this.router.navigate(["/pages/errors/unauthorized"]);
        return false;
      }
      // return true;
    } else if (this.restrictedRoutes.includes(routeNameUn)) {
      console.log("includes")
      return true;
    } else {
      this.router.navigate(["/pages/errors/unauthorized"]);
      return false;
    }
  }

}
